/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-01-15",
    versionChannel: "nightly",
    buildDate: "2023-01-15T00:16:09.461Z",
    commitHash: "31fc94f0ee1ae663aaa6a741c9f0c7d0a20fa3f1",
};
